<template>
  <div>
    <Loading v-if="loading" style="width: 100%"></Loading>
    <div class="demo-card-wide mdl-card mdl-shadow--2dp">

      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text mdl-color-text--black">1. Enter your details</h2>
      </div>

      <div v-if="this.stage === 1">
        <div class="mdl-card__supporting-text">
          <Input v-model="user.email" name="Email" type="email" required/>
          <Input v-model="user.name" name="Name" type="user" required/>
          <Input v-model="user.pass" name="Password" type="password" required/>
          <vue-hcaptcha sitekey="a04b537a-17d4-4a34-9092-ca0583ce459e" @verify="captchaCompleted"/>
            <div style="color:#ff0000">{{this.errorMessage}}</div>
        </div>
        <div class="mdl-card__actions mdl-card--border">
          <div>
            <Button :disabled="!this.captcha" @click="register">Continue</Button>
          </div>
        </div>
      </div>
    </div>


    <div class="demo-card-wide mdl-card mdl-shadow--2dp">

      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text mdl-color-text--black">2. Verify your email</h2>
      </div>

      <div v-if="this.stage === 2">
        <div class="mdl-card__supporting-text">
          We have sent an email containing a code to your email. Please check your spam folder if you cannot find it.
          <Input name="Verification Code" v-model="verificationCode" required/><br>
        </div>
        <div class="mdl-card__actions mdl-card--border">
          <div>
            <Button @click="verify">Continue</Button>
          </div>
        </div>
      </div>

    </div>

    <div class="demo-card-wide mdl-card mdl-shadow--2dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text mdl-color-text--black">3. Finish setting up</h2>
      </div>

      <div v-if="this.stage === 3">
        <div class="mdl-card__supporting-text">
          You are all set! Head to your account page to customize your profile.
        </div>
        <div class="mdl-card__actions mdl-card--border">
          <div>
            <router-link to="/user">
              <Button>Go to account page</Button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import Button from "@/components/Button";
import Input from "@/components/Input";
import Loading from "@/components/Loading";
import ApiError from "@/api/ApiError";
import { client } from "@/main";

export default {
  name: "register",
  data() {
    return {
      stage: 1,
      captcha: null,
      loading: false,
      errorMessage: null,
      user: {
        email: null,
        name: null,
        pass: null,
      },
      verificationCode: null
    }
  },
  components: {
    Loading,
    Input,
    Button,
    VueHcaptcha
  },
  methods: {
    captchaCompleted(token) {
      this.captcha = token
    },
    // eslint-disable-next-line no-unused-vars
    async register() {
      this.loading = true
      try {
        await client.register(
            this.user.email,
            this.user.name,
            this.user.pass,
            this.captcha
        )
        this.loading = false
        this.stage = 2
        this.errorMessage = ""
      } catch(e) {
        this.loading = false
        console.log(e)
        if(e instanceof ApiError) {
          this.errorMessage = e.message
        }
      }

    },
    async verify() {
      this.loading = true
      try{
        await client.validate(this.verificationCode)
        this.loading = false
        this.stage = 3
      } catch(e) {
        this.loading = false
        console.log(e)
        if(e instanceof ApiError) {
          this.errorMessage = e.message
        }
      }
    }
  },
  async login() {
      this.loading = true
      this.loading = false
      this.stage = 4
      this.loading = false
      const redirURL = new URL(localStorage.getItem('redir'))
      redirURL.searchParams.append('token', client.rest.token);
      console.log(redirURL)
      localStorage.removeItem('redir')
      window.location = redirURL.href
    },
  mounted() {
    // set background
    document.body.style.backgroundImage = "url('https://cloud.ibm.com/login/static/cache/2710b-1194401830/img/login_background.jpg')";
    document.body.style.backgroundSize = "cover"
    document.body.style.backgroundRepeat = "no-repeat";
  }
}
</script>
<style scoped>
</style>