<template>
  <div>
  <Loading v-if="loading" style="width: 100%"></Loading>
      <UserEditModal
        v-if="verifyModalOpen"
        @close="verifyModalOpen = false"
        @verify="onUserVerified"
    />
  <div>
    <div class="demo-card-wide mdl-card mdl-shadow--2dp" style="opacity: 0.8 !important;">
        <img id="logo" style="margin-top: 10px; border-radius: 20px;" class="centered" src="https://cdn.snaildos.com/logo/Snail_Portal.png" alt="">
         <br>
      <div class="mdl-card__title">
      </div>
      <h1 class="mdl-card__title-text mdl-color-text--black center" style="padding: 0px; text-align: center;">Welcome   back!</h1>
    <div class="mdl-card__title_text input">
      <div class="center">
      <br>
      <h4 class="mdl-card__title-text mdl-color-text--black" style="padding: 0px; text-align: center;">Please sign into your Bubble ID!</h4>
      <Input v-model="user.email" class="email z" name="Email" type="email" style="color: grey;"/>
      <Input v-model="user.pass" class="password z" name="Password" type="password" style="color: grey;"/>
      <vue-hcaptcha theme="dark" sitekey="a04b537a-17d4-4a34-9092-ca0583ce459e" @verify="captchaCompleted"/>
      <div style="color:#ff0000">{{this.errorMessage}}</div>
    </div>
    </div>
    <div class="mdl-card__actions mdl-card--border" >
      <router-link to="/register">
        <Button style="float:left;">Sign up</Button>
      </router-link>
        <Button style="float:right;" :disabled="!this.captcha" @click="login">Continue</Button>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import Button from "@/components/Button";
import Input from "@/components/Input";
import ApiError from "@/api/ApiError";
import Loading from "@/components/Loading";
import { client } from "@/main.js"
import UserEditModal from "@/modals/UserVerifyModal";

export default {
  name: "login",
  data() {
    return {
      stage: 1,
      loading: false,
      captcha: null,
      user: {
        email: "",
        pass: ""
      },
      errorMessage: "",
      verifyModalOpen: false
    }
  },
  components: {
    UserEditModal,
    Input,
    Button,
    VueHcaptcha,
    Loading
  },
  methods: {
    captchaCompleted(token) {
      this.captcha = token
    },
    async login() {
      this.loading = true
      try {
        await client.login(
          this.user.email,
          this.user.pass,
          this.captcha
        )

        await client.fetchProfile() //we need to see if the user is verified

        this.loading = false
        if(this.$route.query.redir) {
          const redirURL = new URL(this.$route.query.redir)
                    redirURL.searchParams.append('token', client.rest.token);
          console.log(redirURL)
          window.location = redirURL.href
        }
        else this.$router.push('/user')
      } catch(e) {
        console.log(e)
        this.loading = false
        if(e instanceof ApiError) {
          if(e.errorCode === "user.verification") {
            this.verifyModalOpen = true
            this.errorMessage = "Please Verify Me"
          }
          this.errorMessage = e.message
        }
      }
    },
    async onUserVerified() {
      if(this.$route.query.redir) {
        await this.$router.push(this.$route.query.redir)
      }
      else this.$router.push('/user')
    }
  },
  mounted() {
    //fix for MDL dynamic
    // setup background
    document.body.style.backgroundImage = "url('https://cloud.ibm.com/login/static/cache/2710b-1194401830/img/login_background.jpg')";
    document.body.style.backgroundSize = "cover"
    document.body.style.backgroundRepeat = "no-repeat";
    // Check if user is already logged in
    const tokenin = localStorage.getItem('token');
    if (tokenin) {
    if ((localStorage.getItem('token')) === 'null') {
       console.log("Token is null.")
    } else {
    // this.$router.push('/user') TODO
    }
    }

    if(this.$route.query.redir) {
          const redirURL = new URL(this.$route.query.redir)
          this.redirUrlz = redirURL
          console.log(redirURL)
          localStorage.setItem('redir', redirURL)
    }
    const elements = document.getElementsByTagName('*')
    for (let i=0, max=elements.length; i < max; i++) {
      try{
        // eslint-disable-next-line no-undef
          componentHandler.upgradeElement(elements[i])
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }
  },
}
</script>

<style scoped>

div,h1,h2,h3,h4,p {
  color: white;
}

body{
    margin:0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
Input {
    width:100%;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e00000;
    background-color: transparent;
    font-size: 16px;
}
.form-group label{
    position: absolute;
    left:10px;
    top:9px;
    color:#000000;
    font-size:16px;
    padding:0px 4px;
    background-color: rgb(0, 0, 0);
    transition-property: transform;
    transition-duration: 500ms;
    z-index:-1;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.email:focus + label{
    transform: translate3d(0, -100%, 0);
    z-index:1;
    color:#1a73e8;
}
.password:focus + label{
    transform: translate3d(0, -100%, 0);
    z-index:1;
    color:#1a73e8;
}

#logo{
    width:30%;
    margin:0 auto;
}

#eye-icon{
    position: absolute;
    right: 12px;
    top: 10px;
    font-size: 22px;
    cursor: pointer;
}
a{
    text-decoration: none;
}
</style>