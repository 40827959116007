import ApiError from "./ApiError"

/**
* REST Api Handler
*/
 export default class REST {
    constructor(token) {
        this.token = token
        this.baseURL = "https://api.snaildos.com"
    }
 
    /**
     * Sends a request.
     * @param path The path relative to base url
     * @param options The options of the request
     * @param auth Whether the endpoint requires authenication or not
     * @returns The fetch res object
     */
    async request(path, options, auth=true) {
        if(auth && !this.is_logged_in) throw new Error('Client does not have a token.', 401)
        const response = await fetch(
            `${this.baseURL}${path}`,
            Object.assign(options, {
                headers: {
                    'content-type': 'application/json',
                    authorization: (auth ? this.token : null)
                }
            })
        )
            
        const resdata = await response.json()

        if(resdata.status) return resdata

        else switch(response.status) {
            case 100: return
            case 401:
                throw new ApiError('Remote invalidated token', 401, resdata);
            case 404: return null
            case 429: throw new ApiError('Too many requests, please try again later', 429, resdata)
            default: throw new ApiError(resdata.message | 'An Unknown Error Occurred', response.status, resdata)
        }
    }

    get is_logged_in() {
        return this.token ? true : false
    }

    /**
     * Sends a GET request.
     * @param path Path to the request.
     * @param auth Whether authenication is required or not.
     */
    async get(path, auth) {return this.request(path, {}, auth)}

    /**
     * Sends a POST request.
     * @param path Path to the request.
     * @param body The request body.
     * @param auth Whether authenication is required or not.
     */
    async post(path, body, auth) {
        return this.request(path, {method: 'post', body: JSON.stringify(body)}, auth)
    }
    
    /**
     * Sends a PATCH request.
     * @param path Path to the request.
     * @param body The request body.
     * @param auth Whether authenication is required or not.
     */
    async patch(path, body, auth) {
        return this.request(path, {method: 'patch', body: JSON.stringify(body)}, auth)
    }
    

    /**
     * Sends a DELETE request.
     * @param path Path to the request.
     * @param auth Whether authenication is required or not.
     */
    async delete(path, auth) {return this.request(path, {method: 'delete'}, auth)}
}